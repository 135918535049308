import * as React from "react";
import { Link, PageProps } from "gatsby";
import brandLogo from "../../images/brand-logo.png";
import iataArmLogo from "../../images/IATA-ARM_SystemProvider_RGB.png";
import linkedinIcon from "../../images/linkedin.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-lg-12 col-xl-4">
            <p>
              <img width="200px" src={brandLogo} alt="" />
            </p>
            <p>
              A simpler, consistent and modern travel booking platform for
              travel agencies, airlines and other travel providers.
            </p>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4">
            <h3 className="heading">Usefull Links</h3>
            <div className="row">
              <div className="col-md-6">
                <ul className="footer-links list-style-none">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/#contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/news">News Room</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="footer-links list-style-none">
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookies-policy">Cookie Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 iata-arm">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-6">
                <h3 className="heading">IATA NDC Certified</h3>
                <p className="iata-arm-logo">
                  <img
                    width="110px"
                    src={iataArmLogo}
                    alt="IATA NDC Certified"
                  />
                </p>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6">
                <h3 className="heading">Follow Us On</h3>
                <p className="social-icons">
                  <a
                    href="https://www.linkedin.com/company/nuflights/"
                    target="_blank"
                  >
                    <img src={linkedinIcon} alt="linkedin" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="copyright">
              {" "}
              Copyright &copy; 2023-2024 Nuflights LLC. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
