import * as React from "react";
import { Link, PageProps } from "gatsby";
import brandLogo from "../../images/brand-logo.png";
import whiteBrandLogo from "../../images/logo-white.png";
import * as commonStyles from "./common.module.css";

const Navbar = (props: any) => {
  const offset = props.offset;
  const { pageType } = props;
  return (
    <nav className={offset && offset > 400 ? "fixed" : pageType ? "fixed" : ""}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-2 logo-mobile-xs">
            <Link to="/">
              {offset && offset > 400 ? (
                <img width="250px" src={brandLogo} id="logo" alt="" />
              ) : pageType ? (
                <img width="250px" src={brandLogo} id="logo" alt="" />
              ) : (
                <img width="250px" src={whiteBrandLogo} id="logo" alt="" />
              )}
            </Link>
          </div>
          <div className="col-md-8 col-10 text-end links links-mobile-xs">
            <Link to="/#contact" className="contact ">
              Contact us
            </Link>
            <a
              href="https://home.v3.nuflights.com/auth/login"
              target="_blank"
              className="login"
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
